import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="delivery-field"
export default class extends Controller {
  static targets = ["container"]

  remove(event) {
    event.preventDefault()
    const container = this.containerTarget
    container.remove()
  }
}