import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="customer_select"
export default class extends Controller {
  connect() {
    document.addEventListener("click", this.outsideClickClose.bind(this))
  }

  disconnect() {
    document.removeEventListener("click", this.outsideClickClose.bind(this))
  }

  close() {
    const elements = document.getElementsByClassName("slideover-control-div")
    for (let element of elements) {
      element.classList.add("fade-out")
      setTimeout(() => {
        element.classList.add("hidden")
        element.classList.remove("fade-out")
      }, 300) // Adjust this time to match your CSS transition duration
    }
  }

  outsideClickClose(event) {
    const elements = document.getElementsByClassName("slideover-control-div")
    for (let element of elements) {
      if (!element.contains(event.target)) {
        this.close()
      }
    }
  }
}
